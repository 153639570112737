import {
  createUseQueryHook,
  createSsrQuery,
  createSsrCache,
  createUseMutationHook,
  createUsePaginatedQueryHook,
  createCustomClient,
  createSsrMutation,
  createUseAuthQueryHook,
  createUsePaginatedAuthQueryHook,
  createSsrAuthQuery,
  createSsrOptionalAuthQuery,
  createSsrAuthMutation,
} from '@vgn-medien-holding/vgn-gql-shared-fe-queries';

const url = process.env.NEXT_PUBLIC_CMS_API_URL ?? '';
const apiToken = process.env.NEXT_PUBLIC_CMS_API_TOKEN ?? '';
const includeAuthExchange = true;

const ssrCache = createSsrCache();
const client = createCustomClient({ url, apiToken, ssrCache, includeAuthExchange });
const ssrQuery = createSsrQuery(client, apiToken);
const ssrMutation = createSsrMutation(client, apiToken);
const ssrAuthQuery = createSsrAuthQuery(client);
const ssrOptionalAuthQuery = createSsrOptionalAuthQuery(client);
const ssrAuthMutation = createSsrAuthMutation(client);

const useQuery = createUseQueryHook();
const useMutation = createUseMutationHook();
const useAuthQuery = createUseAuthQueryHook();
const usePaginatedQuery = createUsePaginatedQueryHook();
const usePaginatedAuthQuery = createUsePaginatedAuthQueryHook();

export function createUrqlClient() {
  const ssrCache = createSsrCache();
  const client = createCustomClient({ url, apiToken, ssrCache, includeAuthExchange });
  const ssrQuery = createSsrQuery(client, apiToken);
  const ssrMutation = createSsrMutation(client, apiToken);
  const ssrAuthQuery = createSsrAuthQuery(client);
  const ssrOptionalAuthQuery = createSsrOptionalAuthQuery(client);
  const ssrAuthMutation = createSsrAuthMutation(client);

  return {
    client,
    ssrCache,
    ssrQuery,
    ssrMutation,
    ssrAuthQuery,
    ssrAuthMutation,
    ssrOptionalAuthQuery,
  };
}

export {
  client,
  ssrCache,
  ssrQuery,
  ssrMutation,
  ssrAuthQuery,
  ssrAuthMutation,
  ssrOptionalAuthQuery,
  useQuery,
  useMutation,
  useAuthQuery,
  usePaginatedQuery,
  usePaginatedAuthQuery,
};
